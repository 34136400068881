<template>
  <div>
    <div v-if="!sentSuccess">
      <form
        id="fllamada"
        method="POST"
        action="#"
        @submit.prevent="onSubmit"
      >
        <b-row>
          <b-col
            md="6"
            sm="12"
            class="form-country"
          >
            <b-form-input
              id="nombre"
              v-model="form.nombre"
              class="mt-1"
              name="nombre"
              type="text"
              :placeholder="$t('Name')"
              required
            />
          </b-col>
          <b-col
            md="6"
            sm="12"
            class="form-country"
          >
            <b-form-input
              id="email"
              v-model="form.email"
              class="mt-1"
              name="email"
              type="email"
              :placeholder="$t('masteresPosgrados.openDay.llamada.femail')"
              required
            />
          </b-col>
          <b-col
            md="12"
            sm="12"
            class="form-country"
          >
            <b-row>
              <b-col cols="4">
                <b-form-select
                  id="code"
                  v-model="form.code"
                  class="mt-1"
                  name="code"
                  :options="code"
                  required
                />
              </b-col>
              <b-col cols="8">
                <b-form-input
                  id="sms"
                  v-model="form.sms"
                  class="mt-1"
                  name="sms"
                  type="number"
                  :placeholder="$t('Mobile number')"
                  required
                />
              </b-col>
            </b-row>
          </b-col>
          <div class="col-sm-12 col-lg-12">
            <div class="form-group">
              <textarea
                v-model="form.comment"
                rows="8"
                class="form-control"
                maxlength="500"
                name="COMENTARIOS"
                placeholder="Tell us how we can help you....."
              />
            </div>
          </div>
          <div style="padding-bottom:16px">
            <div
              class="sib-form-block"
              style="font-size:12px; text-align:left; font-family:&quot;Helvetica&quot;, sans-serif; color:#3C4858; background-color:transparent; border-width:px;"
            >
              <div class="sib-text-form-block">
                <p>
                  You can consult the additional and detailed information on Data Protection in our <a
                    style="cursor:pointer; color: rgb(4, 101, 245);"
                    @click="showModal()"
                  >Privacy Policy</a>.&nbsp;
                </p>
                <p><strong>Responsible for the treatment</strong>: Aicad Business School S.L.</p>
                <p><strong>Purpose of the data</strong>: Sending newsletters and offers.</p>
                <p><strong>Data storage</strong>: Database hosted in the EU, and with international management transfers to third countries.</p>
                <p>
                  <strong>Rights</strong>: At any time you can
                  <a 
                    style="cursor:pointer; color: rgb(4, 101, 245);" 
                    @click="showModal()">limit, recover, rectify, delete and erase your information.</a>
                </p>
              </div>
            </div>
          </div>
          <b-col
            md="6"
            sm="12"
            class="pt-1 text-left form-country"
          >
            <b-form-checkbox
              id="aceptar"
              v-model="form.aceptar"
              name="aceptar"
              class="mt-1"
              value="1"
              unchecked-value="0"
              @change="aceptPolitica"
            >
              <span>{{ $t('I have read and accept the terms') }}</span>
              <a
                :href="url"
                target="_blank"
              > {{ $t('Of privacy*') }}</a>
            </b-form-checkbox>
          </b-col>
          <b-col
            md="6"
            sm="12"
            class="pt-1 text-right form-country"
          >
            <b-button
              class="buttonContacto"
              type="submit"
              :disabled="enviar == 0"
            >
              {{ $t('Contact us') }}
              <font-awesome-icon
                :icon="['fas', 'angle-right']"
                class="ml-2"
                size="lg"
              />
            </b-button>
          </b-col>
        </b-row>
      </form>
    </div>
    <div
      v-if="sentSuccess"
      class="send-success"
    >
      <h2>{{ $t('common_contact_form.thanks_message') }}</h2>
      <h3>
        {{ $t('common_contact_form.will_contact') }}
      </h3>
      <div
        class="contact-info text-center mt-4"
        style="color:#fff"
      >
        <h4>Puedes contactarme por</h4>
        <div class="contact-nfo">
          <font-awesome-icon
            icon="phone"
            class="phone-icon"
          />
          <a
            href="tel:+34928354668"
            style="color:#fff"
          >+34 928 354 668</a>
        </div>
      </div>
    </div>
    <modal
      name="modal-info"
      height="75%"
      :scrollable="true"
      styles="overflow:auto;padding:20px"
    >
      <div class="col-sm-12">
        <h1>Legal Notice Privacy</h1>
        <div class="privacidad">
          <div
            class="et_pb_module et_pb_text et_pb_text_0 et_pb_bg_layout_light  et_pb_text_align_left"
            style="box-sizing: border-box; margin: 0px 0px 29.6875px; padding: 0px; border: 0px; outline: 0px; background-image: initial; background-position: center center; background-size: cover; background-repeat: no-repeat; background-attachment: initial; background-origin: initial; background-clip: initial; vertical-align: baseline; text-size-adjust: 100%; overflow-wrap: break-word; animation-timing-function: linear; animation-duration: 0.2s; color: rgb(102, 102, 102); font-family: &quot;Open Sans&quot;, Helvetica, Arial, Lucida, sans-serif;"
          >
            <div
              class="et_pb_text_inner"
              style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; position: relative;"
            >
              <h3
                class="h3_each_static_section"
                style="box-sizing: border-box; margin: 0px; padding: 0px 0px 10px; border: 0px; outline: 0px; background: 0px 0px; font-size: 22px; vertical-align: baseline; text-size-adjust: 100%; color: rgb(51, 51, 51); font-weight: 500; line-height: 1em; font-family: Oswald, Helvetica, Arial, Lucida, sans-serif;"
              >
                Ownership
              </h3>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                In compliance with the provisions of Law 34/2002, of July 11, Services of the Information Society and Electronic Commerce, it is reported that the owner of this website is the following entity:
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; text-align: center;">
                AICAD BUSINESS SCHOOL SL con CIF: B76062199, Company registered in the Mercantile Registry of Las Palmas 04/30/2010 Volume 1958 Book 0 Folio 116 and with registered office at Calle León y Castillo 54, 1º Planta, 35003, Las Palmas de Gran Canaria.
              </p>
              <h3
                class="h3_each_static_section"
                style="box-sizing: border-box; margin: 0px; padding: 0px 0px 10px; border: 0px; outline: 0px; background: 0px 0px; font-size: 22px; vertical-align: baseline; text-size-adjust: 100%; color: rgb(51, 51, 51); font-weight: 500; line-height: 1em; font-family: Oswald, Helvetica, Arial, Lucida, sans-serif;"
              >
                Acceptance of the legal notice
              </h3>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                We remind you that Aicad offers its products and services through the Internet platform websincoste.com (www.websincoste.com), hereinafter Aicad, and the user who accesses it, either through a personal computer, or Through any application developed for "smartphones" or by any other means and that, where appropriate, uses the features offered by it. Consequently, the mere access to Aicad by any means implies acceptance by the user of these conditions and terms of use. You can contact us by email info@websincoste.com or by phone (+34) 928 35 46 68 to request more information.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; text-align: center;">
                <span style="box-sizing: border-box; margin: 0px; padding: 0cm; border: 1pt none windowtext; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-family: &quot;Open Sans&quot;, sans-serif; color: olive;">Date of last revision: April 17, 2018</span>
              </p>
            </div>
          </div>
          <div
            class="et_pb_module et_pb_text et_pb_text_1 et_pb_bg_layout_light  et_pb_text_align_left"
            style="box-sizing: border-box; margin: 0px 0px 29.6875px; padding: 0px; border: 0px; outline: 0px; background-image: initial; background-position: center center; background-size: cover; background-repeat: no-repeat; background-attachment: initial; background-origin: initial; background-clip: initial; vertical-align: baseline; text-size-adjust: 100%; overflow-wrap: break-word; animation-timing-function: linear; animation-duration: 0.2s; color: rgb(102, 102, 102); font-family: &quot;Open Sans&quot;, Helvetica, Arial, Lucida, sans-serif;"
          >
            <div
              class="et_pb_text_inner"
              style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; position: relative;"
            >
              <h2 style="box-sizing: border-box; margin: 0px; padding: 0px 0px 10px; border: 0px; outline: 0px; background: 0px 0px; font-size: 26px; vertical-align: baseline; text-size-adjust: 100%; color: rgb(51, 51, 51); font-weight: 500; line-height: 1em; font-family: Oswald, Helvetica, Arial, Lucida, sans-serif;">
                <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; font-size: large; vertical-align: baseline; text-size-adjust: 100%;"><span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; font-size: 18px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">LEGAL NOTICE OF THE PORTAL OF AICAD BUSINESS SCHOOL</span></span>
              </h2>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                The clauses listed below regulate the conditions of use of the Internet Web Portal “www.websincoste.com” (hereinafter, the Portal) de Aicad Business School, S.L.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                The expression "user" includes any person who accesses the Portal, either directly or from any other Internet site. The general conditions that regulate the use of the Portal are the following:
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">1.- GENERIC.</span>
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                The services offered by Aicad Business School, S.L. in this Internet site are regulated by the clauses contained in these conditions (without prejudice to the other legal notices and conditions that appear on the Portal).
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                Aicad Business School, S.L. reserves the right to modify, in whole or in part, these general conditions, the new ones agreed upon from the moment of their insertion on the page being applicable.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">2.- IDENTIFICATION AND COMPANY NAME OF THE OFFERING COMPANY.</span>
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                AICAD BUSINESS SCHOOL SL con CIF: B76062199, Company registered in the Mercantile Registry of Las Palmas 04/30/2010 Volume 1958 Book 0 Folio 116 and domiciled at Calle León y Castillo 54, 1º Planta, 53003, Las Palmas de Gran Canaria, whose commercial name is Aicad Business School, S.L.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                For any query, suggestion or claim, the user can go to the offices of our company located at the address mentioned above, or to the email address&nbsp;<a
                  href="mailto:info@websincoste.com"
                  style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; color: rgb(0, 160, 223); text-decoration-line: none;"
                >info@websincoste.com</a>, by phone: +34 928 35 46 68 or using the&nbsp;<a
                  href="https://www.websincoste.com/contacta-con-nosotros/"
                  style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; color: rgb(0, 160, 223); text-decoration-line: none;"
                >Contact Form</a>.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">3.- USER ACCESS CONDITIONS.</span>
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                The user undertakes to use the information contained in this Internet site for the purposes of the Portal exclusively, specifically for the purpose of obtaining information about the services offered and making suggestions and not to directly or indirectly carry out any type of exploitation commercial other than authorized uses.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                The user of the website will have the right to free and free access to the public information collected therein, although Aicad Business School, S.L. reserves the right to restrict access to certain sections and services of the Portal to registered customers.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                Aicad Business School, S.L. strictly prohibits the use of items restricted to a certain group of users to unauthorized users by name and password.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                Aicad Business School, S.L. has made available to users authorized to access the private area of ​​the website a name and password whose custody is the sole and exclusive responsibility of the user. Consequently, Aicad Business School, S.L. is not responsible under any circumstances for the misuse of usernames and passwords.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">4.- INTELLECTUAL AND INDUSTRIAL PROPERTY.</span>
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                The rights related to industrial and intellectual property (by way of example and not limited to brands, logos, texts, photographs, icons, images, etc., as well as graphic design, source code and other software elements contained in this page Web), are property of Aicad Business School, SL or of the other entities that can offer information through the Portal. Therefore, they are subject to intellectual and industrial property rights protected by Spanish and international legislation.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                Without the prior written authorization of Aicad Business School, S.L. or, where appropriate, of the entity that owns the rights, it is not allowed to use, reproduce, transmit, manipulate, as well as make any use that exceeds the visit of the page and the use of the services offered.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                Access to the services provided does not imply on the part of Aicad Business School, SL, nor on the part of the respective owner, waiver, transmission, or total or partial transfer of the rights derived from intellectual and industrial property, nor does it attribute rights of use, alteration, exploitation, reproduction, distribution or public communication of these contents without the prior and express authorization in writing of the respective holders of said rights, without prejudice to the right to view and obtain a private security copy of such contents, provided that said right is exercised according to the principles of good faith and provided that the intellectual and industrial property of the holder of such rights remains unaltered, it is used for non-commercial purposes and exclusively for the user's personal information.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                The responsibility derived from the use of materials protected by the intellectual and industrial property rights contained in this page will correspond exclusively to the user.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">5.- OBJECT OF THE PORTAL.</span>
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                This page has been designed by Aicad Business School, S.L. to provide information about their services and other company activities. The web pages have contact information and forms through which users can contact the company for the purposes provided in each case, according to the regulation of the Portal clauses.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                The mere access to the Website, the completion of forms, the sending of information requests, inquiries, complaints, hiring offers, curriculum vitae and, in general, any act of a similar nature to the previous ones carried out through the forms and / or existing electronic mailboxes on the Website will imply, for its part, the unreserved acceptance of each and every one of the rules that make up this Legal Notice and the acquisition of the consideration of User of the Website. Consequently, you must read carefully and know the content of this Legal Notice.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                Aicad Business School, S.L. reserves the right to make as many modifications and updates as it deems appropriate on the website.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">6.- DISCLAIMER OF LIABILITY.</span>
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                Aicad Business School, S.L. will not be liable for interruptions that may occur in electrical or telecommunications services that prevent users from using the services offered. Aicad Business School, S.L. will not respond to any possible liability for damages of any kind that may be due to the lack of veracity, validity, completeness or authenticity of the information provided by users.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                Likewise, Aicad Business School, S.L. will not be liable for the web pages to which it may refer through links, banners or other devices, nor for the content that they may include.
              </p>
            </div>
          </div>
          <div
            class="et_pb_module et_pb_text et_pb_text_2 et_pb_bg_layout_light  et_pb_text_align_left"
            style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background-image: initial; background-position: center center; background-size: cover; background-repeat: no-repeat; background-attachment: initial; background-origin: initial; background-clip: initial; vertical-align: baseline; text-size-adjust: 100%; overflow-wrap: break-word; animation-timing-function: linear; animation-duration: 0.2s; color: rgb(102, 102, 102); font-family: &quot;Open Sans&quot;, Helvetica, Arial, Lucida, sans-serif;"
          >
            <div
              class="et_pb_text_inner"
              style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; position: relative;"
            >
              <h2 style="box-sizing: border-box; margin: 0px; padding: 0px 0px 10px; border: 0px; outline: 0px; background: 0px 0px; font-size: 26px; vertical-align: baseline; text-size-adjust: 100%; color: rgb(51, 51, 51); font-weight: 500; line-height: 1em; font-family: Oswald, Helvetica, Arial, Lucida, sans-serif;">
                <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">PERSONAL DATA PROTECTION</span>
              </h2>
              <h3
                class="h3_each_static_section"
                style="box-sizing: border-box; margin: 0px; padding: 0px 0px 10px; border: 0px; outline: 0px; background: 0px 0px; font-size: 22px; vertical-align: baseline; text-size-adjust: 100%; color: rgb(51, 51, 51); font-weight: 500; line-height: 1em; font-family: Oswald, Helvetica, Arial, Lucida, sans-serif;"
              >
                For what purpose do we process your personal data?
              </h3>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                In accordance with the provisions of the General Data Protection Regulation (EU) 2016/679 (hereinafter, RGPD) and other regulations in force regarding the protection of personal data, we inform you that your personal data are treated as responsible of the treatment by AICAD BUSINESS SCHOOL SL (hereinafter, Aicad), with registered office at Calle León y Castillo 54, 1º Planta, 53003, Las Palmas de Gran Canaria.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">a) Information</span>
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                The information received by Aicad Business School, S.L. Through any of its web pages it will be treated with the utmost reserve and confidentiality in accordance with current regulations.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                Aicad Business School S.L. will process your personal data in order to manage the relationship you maintain with us, as well as to send you commercial communications by any means, even after the relationship with you has ended, to keep you informed of those of our products and services similar to those contracted by you.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">b) Sending and recording of personal data</span>
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                Sending personal data on this website is mandatory to contact, comment or contract any product available for sale on our website.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                Likewise, not providing the requested personal data or not accepting this data protection policy means that it is impossible to subscribe to content and process the requests made on this website.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                You do not need to provide any personal data to browse this website.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                The cases in which this website requires personal data are:
              </p>
              <ul style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; list-style-position: initial; list-style-image: initial; line-height: 26px;">
                <li style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                  To subscribe to the newsletter
                </li>
                <li style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                  To comment on posts published on the blog.
                </li>
                <li style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                  To download some of the materials offered on the blog.
                </li>
                <li style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                  To make inquiries through the contact form.
                </li>
                <li style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                  To share content on social networks.
                </li>
                <li style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                  To apply for job offers.
                </li>
                <li style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                  To contract the services available on this website
                </li>
              </ul>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">c) Exercise of ARCO rights, the right to be forgotten and portability<br style="box-sizing: border-box;"></span>
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                To exercise the rights of access, rectification, oblivion, portability, cancellation and opposition provided for in the Law, you can write to&nbsp;<span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">to Aicad Business School, S.L. Ref. Data protection, Calle León y Castillo 54, 1º Planta, 53003, Las Palmas de Gran Canaria or to the email address&nbsp;<a
                  href="mailto:info@websincoste.com"
                  style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; color: rgb(0, 160, 223); text-decoration-line: none;"
                >info@websincoste.com</a>.&nbsp;</span>In the absence of any contractual relationship,&nbsp;<span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; text-decoration-line: underline;">you can delete your account</span>&nbsp;whenever you want from the user panel, accessing your customer profile.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                Aicad Business School, S.L. declares and guarantees that it maintains the security levels of protection of personal data in accordance with Royal Decree 994/1999, of June 11, regarding the security measures of automated files containing personal data; that it has established all the technical means at its disposal to avoid the loss, misuse, alteration, unauthorized access and theft of data provided by users, without prejudice to informing that Internet security measures are not impregnable.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">ACCURACY AND TRUTH OF THE DATA</span>
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                The user is solely responsible for the veracity and correctness of the data sent to the portal (or to its affiliates, partners and collaborators), exonerating the Provider from any responsibility in this regard. Users guarantee and respond, in any case, to the accuracy, validity and authenticity of the personal data provided, and undertake to keep them duly updated. The user agrees to provide complete and correct information in the contact or subscription form.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">ACCEPTANCE AND CONSENT</span>
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                The legal basis for the processing of your personal data is the execution and maintenance of the relationship maintained with you, as well as the express consent that, where appropriate, you have provided us for additional treatments consisting of sending your own commercial communications and / or or third parties, the development of commercial profiles and the transfer of said data and profiles with&nbsp;<em style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">the purposes indicated in the section “<span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">Sending and recording of personal data</span>”</em>&nbsp;Previous.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                For any other use other than those expressly permitted, it will be necessary to obtain the prior written consent of the owner of the rights in question.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">The acceptance and consent of the interested party:</span>&nbsp;In those cases where to make a request it is necessary to fill in a form and click on the send button, our forms have the * symbol in the mandatory data. If you do not provide these fields, or do not mark the "checkbox" of acceptance of the privacy policy, the sending of the information will not be allowed. It normally has the following formula: "□ I have read and accept the Privacy and / or Data Protection Policy."
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">PERSONAL DATA CAPTURE SYSTEMS USED BY THIS WEBSITE</span>
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                This website uses different systems for capturing personal information. This website always requires the prior consent of users to process their personal data for the indicated purposes.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                The user has the right to revoke their prior consent at any time.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                Personal information capture systems used by Aicad:
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                1)&nbsp; Content subscription forms: within the web there are several forms to activate the subscription. You will need to confirm your subscription in order to validate your email address. The data provided will be used exclusively to send the Newsletter and keep you updated on news and specific, exclusive offers.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                2)&nbsp; Comments form: The web includes a form to comment on articles, leave your review for the products and / or other posts of the personalized type. The user will be able to post comments on the posts that are published. The personal data entered in the form to insert these comments will be used exclusively to moderate and publish them.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                3)&nbsp; Contact form: There is also a contact form for inquiries, suggestions or service contracting. In this case, the email address will be used to respond to them and send the information that the user requires through the web.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                4)&nbsp; Cookies: When the user registers or browses this website, "cookies" are stored. The user can consult the cookie policy at any time to obtain more information on the use of cookies and how to deactivate them.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                5)&nbsp; Download Systems: On this website you can download different content that is periodically incorporated into our blog, in other portals belonging to Aicad and users have the possibility of downloading any course syllabus in PDF format.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                6)&nbsp; Navigation and IP Address: When browsing this website, the user automatically provides the web server with information regarding your IP address, date and time of access, the hyperlink that has been forwarded to them, your operating system and the browser used. The Spanish Agency for Data Protection considers the IP as personal data. This website will not use the IP to identify its users, this data will be saved and used only for the control and realization of statistics of access and visits to the web, to analyze trends, administer the site, and to collect demographic information on our basis. of users as a whole. In no case will they be communicated or transferred to third parties.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">COMMERCIAL MAIL</span>
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                In accordance with the LSSICE, Aicad does not carry out SPAM practices, so it does not send commercial emails that have not been previously requested or authorized by the User, on some occasions, it can send its own promotions and offers and those of third parties, only in cases where you have the authorization of the recipients. Consequently, in each of the forms provided on the Website, the User has the possibility of giving their express consent to receive my "Newsletter", regardless of the commercial information specifically requested. You can also cancel your subscription automatically in the same Newsletters..
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">SECURITY MEASURES</span>
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                In accordance with current legislation, the person in charge of this site has adopted the levels and security measures for the protection of personal data that are established in the General Data Protection Regulation (EU) 2016/679 for the protection of personal data. personal, respecting the obligations and the duty of confidentiality established by law, and installing in their systems and files the necessary technical measures to guarantee the confidential treatment of the data, avoid its loss, alteration and unauthorized access.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">Protection policy for minors</span>
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                Whoever provides the data through the forms on this website and accepts its treatment formally declares to be over 14 years of age. Access and use of the portal is prohibited to minors under 14 years of age.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                Aicad reminds people of legal age who are in charge of minors, that it will be their sole responsibility if a minor incorporates their data to request a service.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                It also informs them that there are computer programs to limit browsing by filtering or blocking certain content.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">APPLICABLE LEGISLATION</span>
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                All disputes, controversies or claims arising from the interpretation or execution of the general conditions of sale will be governed by Spanish law, and will be subject to the jurisdiction of the Courts and Tribunals of the website owner's domicile.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">CHANGES TO THIS PRIVACY POLICY</span>
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                Aicad Business School reserves the right to modify this policy to adapt it to new legislation or jurisprudence, as well as industry practices. In such cases, the Provider will announce on this page the changes introduced with reasonable anticipation of their implementation.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                <span style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%; font-weight: 700;">CONFIDENTIALITY</span>
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px 0px 1em; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                All information and documentation used during the contracting, development and execution of the contractual conditions that regulate the relations between Aicad and the Client is confidential. Confidential information shall not be understood as that which is disclosed by agreement between the Parties, that which becomes public for the same reason or that which has to be disclosed in accordance with the law or with a judicial resolution of a competent authority and that which is obtained by a third party that is not under any obligation of confidentiality. Both parties are obliged to comply with the duty of confidentiality and to maintain it for a minimum period of two (2) years after the end of the aforementioned contractual conditions that regulate the relations of Aicad and the Client.
              </p>
              <p style="box-sizing: border-box; margin: 0px; padding: 0px; border: 0px; outline: 0px; background: 0px 0px; vertical-align: baseline; text-size-adjust: 100%;">
                All information received by the client, be it images, texts, access data such as WordPress users and passwords, hosting or others, will be treated confidentially, the transfer to third parties being totally prohibited unless we have their consent and always for the same purpose for which the data has been obtained.
              </p>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<style scoped lang="scss">
.form-country {
	padding: 15px 10px;
}
div.send-success {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	height: 100%;
}

#sib-form div {
	color: #fff;
}
#sib-form div,
#sib-form h1,
#sib-form p,
#sib-form label,
#sib-form span {
	color: #fff !important;
}
.sib-form {
	background-color: transparent !important;
	padding: 16px 12px !important;
}
#sib-container {
	background-color: rgba(0, 0, 0, 0) !important;
	border: none !important;
	padding: 10px !important;
}
#sib-form option,
#sib-form select,
#sib-form input {
	color: #000;
}
#sib-form input::placeholder {
	color: #000;
	padding-left: 10px;
}
.sib-text-form-block p {
	font-size: 13px !important;
}
.sib-optin.sib-form-block {
	padding: 0 40px;
}
p {
  margin-top: 0;
  margin-bottom: 0rem;
}
</style>
<script>
export default {
    data() {
        return {
            form: {
                nombre: null,
                email: null,
                apellido1: null,
                apellido2: null,
                pais: 'Spain',
                code: '+34',
                sms: null,
                campus: null,
                comment: '',
                master: null,
                aceptar: 0,
                web: null
            },
            sentSuccess: false,
            sentFailed: false,
            enviar: 0,
            code: [
                { value: '+34', text: '+34 ES' },
                { value: '+33', text: '+33 FR' },
                { value: '+91', text: '+91 IN' },
                { value: '+687', text: '+687 NC' },
                { value: '+689', text: '+689 PF' },
                { value: '+262', text: '+262 YT' },
                { value: '+508', text: '+508 PM' },
                { value: '+681', text: '+681 WF' },
                { value: '+352', text: '+352 LU' },
                { value: '+63', text: '+63 PH' },
                { value: '+27', text: '+27 ZA' },
                { value: '+64', text: '+64 NZ' },
                { value: '+420', text: '+420 CZ' },
                { value: '+31', text: '+31 NL' },
                { value: '+1', text: '+1 PR' },
                { value: '+41', text: '+41 CH' },
                { value: '+44', text: '+44 GB' },
                { value: '+230', text: '+230 MU' },
                { value: '+590', text: '+590 BL' },
                { value: '+594', text: '+594 GF' },
                { value: '+596', text: '+596 MQ' },
                { value: '+269', text: '+269 KM' },
                { value: '+971', text: '+971 AE' },
                { value: '+387', text: '+387 BA' },
                { value: '+880', text: '+880 BD' },
                { value: '+852', text: '+852 HK' },
                { value: '+32', text: '+32 BE' },
                { value: '+39', text: '+39 IT' },
                { value: '+213', text: '+213 DZ' },
                { value: '+49', text: '+49 DE' },
                { value: '+212', text: '+212 MA' },
                { value: '+86', text: '+86 CN' },
                { value: '+81', text: '+81 JP' },
                { value: '+216', text: '+216 TN' },
                { value: '+972', text: '+972 IL' },
                { value: '+30', text: '+30 GR' },
                { value: '+370', text: '+370 LT' },
                { value: '+60', text: '+60 MY' },
                { value: '+92', text: '+92 PK' },
                { value: '+7', text: '+7 RU' },
                { value: '+356', text: '+356 MT' },
                { value: '+20', text: '+20 EG' },
                { value: '+94', text: '+94 LK' },
                { value: '+886', text: '+886 TW' },
                { value: '+234', text: '+234 NG' },
                { value: '+62', text: '+62 ID' },
                { value: '+45', text: '+45 DK' },
                { value: '+98', text: '+98 IR' },
                { value: '+977', text: '+977 NP' },
                { value: '+90', text: '+90 TR' },
                { value: '+376', text: '+376 AD' },
                { value: '+507', text: '+507 PA' },
                { value: '+380', text: '+380 UA' },
                { value: '+598', text: '+598 UY' },
                { value: '+58', text: '+58 VE' },
                { value: '+84', text: '+84 VN' },
                { value: '+54', text: '+54 AR' },
                { value: '+52', text: '+52 MX' },
                { value: '+48', text: '+48 PL' },
                { value: '+386', text: '+386 SI' },
                { value: '+385', text: '+385 HR' },
                { value: '+47', text: '+47 NO' },
                { value: '+40', text: '+40 RO' },
                { value: '+46', text: '+46 SE' },
                { value: '+66', text: '+66 TH' },
                { value: '+56', text: '+56 CL' },
                { value: '+353', text: '+353 IE' },
                { value: '+421', text: '+421 SK' },
                { value: '+358', text: '+358 FI' },
                { value: '+61', text: '+61 AU' },
                { value: '+359', text: '+359 BG' },
                { value: '+36', text: '+36 HU' },
                { value: '+43', text: '+43 AT' },
                { value: '+57', text: '+57 CO' },
                { value: '+1', text: '+1 JM' },
                { value: '+595', text: '+595 PY' },
                { value: '+509', text: '+509 HT' },
                { value: '+995', text: '+995 GE' },
                { value: '+372', text: '+372 EE' },
                { value: '+593', text: '+593 EC' },
                { value: '+962', text: '+962 JO' },
                { value: '+973', text: '+973 BH' },
                { value: '+261', text: '+261 MG' },
                { value: '+65', text: '+65 SG' },
                { value: '+1', text: '+1 US' },
                { value: '+1', text: '+1 CA' },
                { value: '+371', text: '+371 LV' },
                { value: '+961', text: '+961 LB' },
                { value: '+590', text: '+590 FR' },
                { value: '+505', text: '+505 NI' },
                { value: '+351', text: '+351 PT' },
                { value: '+55', text: '+55 BR' },
                { value: '+370', text: '+370 LT' },
                { value: '+51', text: '+51 PE' },
                { value: '+974', text: '+974 QA' },
                { value: '+375', text: '+375 BL' },
                { value: '+233', text: '+233 GH' },
                { value: '+354', text: '+354 IS' },
                { value: '+243', text: '+243 CG' },
                { value: '+1', text: '+1 BS' },
                { value: '+503', text: '+503 SV' }
            ],
            url: '/aviso-legal-privacidad/',
            modalVisible: false
        };
    },
    computed: {
        fpais() {
            return this.$t('masteresPosgrados.openDay.llamada.fpais');
        },
        fcampus() {
            return this.$t('masteresPosgrados.openDay.llamada.fcampus');
        },
        fmaster() {
            return this.$t('masteresPosgrados.openDay.llamada.fmaster');
        }
    },
    methods: {
        onSubmit(event) {
            event.preventDefault();
            this.enviar = 0;
            this.axios
                .post('https://www.websincoste.com/index.php', {
                    _nonce: 'f9d8d3d927baca5e53d95c32b41f6f00',
                    action: 'send_universal_form',
                    serialize: [
                        { name: 'Form Type', value: 'Masteres Posgrados Modal Form' },
                        { name: 'Nombre y apellido', value: this.form.nombre },
                        { name: 'Email', value: this.form.email },
                        // { name: 'Apellido 1', value: this.form.apellido1 },
                        // { name: 'Apellido 2', value: this.form.apellido2 },
                        { name: 'Pais', value: this.form.pais },
                        { name: 'Code', value: this.form.code },
                        { name: 'SMS', value: this.form.sms },
                        { name: 'Campus', value: this.form.campus },
                        { name: 'Comment', value: this.form.comment },
                        { name: 'Master', value: this.form.master },
                        { name: 'Reference Link', value: window.location.href },
                        { name: 'Aceptación Privacidad RGDP:', value: this.form.aceptar }
                    ]
                })
                .then(response => {
                    if (response.status === 200) {
                        this.sentSuccess = true;
                        this.form = {
                            nombre: null,
                            email: null,
                            apellido1: null,
                            apellido2: null,
                            pais: null,
                            code: '+34',
                            sms: null,
                            campus: null,
                            comment: '',
                            master: null,
                            aceptar: null,
                            web: null
                        };
                        setTimeout(() => {
                            this.sentSuccess = false;
                        }, 10000);
                    } else {
                        this.sentFailed = true;
                        let send = this.$t('sendERROR');
                        this.$bvToast.toast(send, {
                            title: this.$t('message.rs'),
                            variant: 'default',
                            autoHideDelay: 3000,
                            solid: true
                        });
                    }
                })
                .catch(error => {
                    this.sentFailed = true;
                    this.sentSuccess = false;
                    console.log(error);
                });
        },
        aceptPolitica(checked) {
            this.enviar = checked == 1;
        },
        showModal: function() {
            this.$modal.show('modal-info');
        },
        hide() {
            this.$modal.hide('modal-info');
        }
    }
};
</script>
